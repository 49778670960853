<template>
  <router-view />
</template>

<script>
import Index from "./views/index";
export default {
  name: "App",
  components: {
    Index,
  },
};
</script>

<style>
html,
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}

fieldset,
img,
input,
button {
  /*fieldset组合表单中的相关元素*/
  border: none;
  padding: 0;
  margin: 0;
  outline-style: none;
}

ul,
ol {
  list-style: none; /*清除列表风格*/
}

input {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "SimSun", "宋体";
}

select,
input {
  vertical-align: middle;
}

select,
input,
textarea {
  font-size: 12px;
  margin: 0;
}

textarea {
  resize: none;
}

/*防止多行文本框拖动*/
img {
  border: 0;
  vertical-align: middle;
}

/*  去掉图片低测默认的3像素空白缝隙*/
table {
  border-collapse: collapse; /*合并外边线*/
}

body {
  font: 12px/150% Arial, Verdana, "\5b8b\4f53"; /*宋体，Unicode，统一码*/
  color: #666;
  background: #fff;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1; /*IE/7/6*/
}

a {
  color: #666;
  text-decoration: none;
}

a:hover {
  color: #c81623;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
  font-size: 100%;
}

s,
i,
em {
  font-style: normal;
  text-decoration: none;
}

.col-red {
  color: #c81623 !important;
}
</style>
