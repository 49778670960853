import { createRouter, createWebHistory } from 'vue-router'
// const routes = [
//     {
//         path: '/',
//         redirect: '/access'
//     },
//     {
//         path: '/access',
//         name: 'access',
//         component: () => import('../views/vrAccess')
//     },
//     {
//         path: '/video',
//         name: 'video',
//         component: () => import('../views/videoPage')
//     },
//     {
//         path: '/lawstate',
//         name: 'lawstate',
//         component: () => import('../views/protocal/lawState.vue')
//     },
//     {
//         path: '/rules',
//         name: 'rules',
//         component: () => import('../views/protocal/protocalRules.vue')
//     }
// ]
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index')
    },
    {
        path: '/details',
        name: 'details',
        component: () => import('../views/exhibitorDetails')
    },
    {
        path: '/goods_details',
        name: 'goods_details',
        component: () => import('../views/goodsDetails')
    },
    {
        path: '/pavilion',
        name: 'pavilion',
        component: () => import('../views/pavilion')
    },
    {
        path: '/goodslist',
        name: 'goodslist',
        component: () => import('../views/goodslist')
    },
    {
        path: '/cloud',
        name: 'cloud',
        component: () => import('../views/cloudTop')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('../views/vrAccess')
    },
    {
        path: '/video',
        name: 'video',
        component: () => import('../views/videoPage')
    },
    {
        path: '/lawstate',
        name: 'lawstate',
        component: () => import('../views/protocal/lawState.vue')
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import('../views/protocal/protocalRules.vue')
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    window.pageYOffset = 0
})
export default router
