<template>
  <div class="footer">
    <img class="logo" src="../../assets/image/logo.png" alt="" />
    <!-- <div style="width: 250px; margin-top: 30px; font-size: 16px; display: flex; justify-content: space-between;">
      <span @click="handleJump('/lawstate')">法律声明及隐私权政策</span>
      <span @click="handleJump('/rules')">协议与规则</span>
    </div> -->
    <div class="footer-bottom" style="margin-top: 30px;">
      中国杨凌农业高新科技成果博览会 &nbsp; 陕ICP备12002710号
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const handleJump = (path) => {
      router.push({
        path: path,
        query: { path: 0 },
      })
    }
    return { handleJump }
  },
})
</script>

<style lang="less" scoped>
.footer {
  margin-top: 100px;
  width: 100%;
  padding: 35px 0 30px 0;
  background: #242b31;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #9fa1a2;
  span {
    cursor: pointer;
  }
  &-top {
    width: 66%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .qrcode-box {
      .title {
        opacity: 0.9;
        font-size: 20px;
        color: #ebeceb;
      }
      .qrcode {
        margin-top: 20px;
        img {
          width: 130px;
          height: 130px;
          margin-left: 30px;
          &:nth-child(1) {
            margin: 0;
          }
        }
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      line-height: 25px;
      .title {
        opacity: 0.9;
        font-size: 20px;
        color: #ebeceb;
        margin-bottom: 20px;
      }
    }
    .logo {
      width: 150px;
      height: 100px;
    }
  }
  &-bottom {
    opacity: 0.5;
    font-size: 14px;
  }
}
</style>
