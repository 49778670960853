<template>
  <div class="wrapper">
    <Header :indexPage="true" @onSearch="handleSearch" />
    <!-- 展会介绍 -->
    <div class="present">
      <div class="title">
        <img src="../../assets/image/title-1.png" alt="" />
      </div>
      <div class="content">
        <div class="description">
          <span>中国杨凌农业高新科技成果博览会（简称：杨凌农高会）
            由科技部、南务部、农业农村部、国家林业和草原局、国家知识产权局、中国科学院和陕西省人民政府共同主办，国家5A级农业综合展会和国际展览业协会（UFI)认证展会。杨凌农高会自1994年创办以来，始终坚守“服务三农”的初心，依托和发挥杨凌示范区的农业科技优势，展会规模不断扩大，展览内容日益丰富，知名度和影响力逐年提升。
          </span>
          <span style="display: block; margin-top: 20px">
            26年来，杨凌农高会累计吸引了70多个国家和地区以及我国30多个省市区的上万家涉农单位、3160多万客商和群众参展参会，参展项目及产品超过17万项，连续六届项目签约投资和交易额均超过了1000亿元，产生了显著的经济和社会效益，对促进我国农业科技创新、苦动特色现代农业发展、加强中外农业科技合作交流发挥了重要作用，经过26年的精心培有，杨凌农高会已成为驰名中外的农业品牌展会，先后获得国际展览业协会(UFI)认证、中国驰名南标认证、全国5A级农业综合展会认证，品牌价值达871.19亿元，位列全国农业区城品牌价值第一名。
          </span>
        </div>
        <div class="content-right">
          <div class="top">
            <span>打造云上展会新平台</span>
            <span>中国杨凌农业高新科技成果博览会</span>
          </div>
          <div class="bot">
            <span>进入3D全景展会</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <ExhibitorList
      :merchantListData="merchantListData"
      :merchantListTotal="merchantListTotal"
    /> -->
    <CloudAndAccess :indexPage="true" :merchantListData="merchantListData" />
    <div style="height: 30px"></div>
    <GoodsList :product="product" :indexPage="true" />
    <!-- <div class="unit">
      <div class="title">
        <img src="../../assets/image/title-1.png" alt="" />
      </div>
      <div class="content">
        <div class="item">
          <span class="title">主办单位</span>
          <div style="display: flex; flex-wrap: wrap">
            <span>中华人民共和国科技部</span>
            <span>中华人民共和国农业农村部</span>
            <span>中华人民共和国商务部</span>
            <span>中国科学院</span>
            <span>国家林业和草原局</span>
            <span>国家知识产权局</span>
            <span>陕西省人民政府</span>
          </div>
        </div>
        <div class="item">
          <span class="title">承办单位</span>
          <div style="display: flex; flex-wrap: wrap"><span>陕西省人民政府</span></div>
        </div>
        <div class="item">
          <span class="title">协办单位</span>
          <div style="display: flex; flex-wrap: wrap">
            <span>联合国粮农组织</span>
            <span>联合国工发组织</span>
            <span>上海合作组织</span>
            <span>东南亚国家联盟</span>
            <span>加拿大农业与食品部</span>
            <span>美国内布拉斯加州政府</span>
            <span>俄罗斯库尔斯克州政府</span>
            <span>德国中小企业联合会</span>
            <span>日本农协</span>
            <span>中国农村技术开发中心</span>
            <span>中国农村专业技术协会</span>
            <span>中国农业国际合作促进会</span>
          </div>
        </div>
        <div class="item">
          <span class="title">支持单位</span>
          <div style="display: flex; flex-wrap: wrap">
            <span>中华全国供销合作总社</span>
            <span>中国科学技术协会</span>
            <span>海峡两岸关系协会</span>
          </div>
        </div>
      </div>
    </div> -->
    <Footer />
  </div>
</template>
<script>
import { DownloadOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import Header from '../../components/Header'
// import ExhibitorList from "../../components/ExhibitorList";
import CloudAndAccess from '../../components/CloudAndAccess'
import GoodsList from '../../components/GoodsList'
import Footer from '../../components/Footer'
import { post_merchant_list, get_merchant_details, IMAGE_URL } from '@/api'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    Header,
    Footer,
    GoodsList,
    // ExhibitorList,
    CloudAndAccess,
    DownloadOutlined,
  },
  data() {
    return {
      merchantListData: [],
      merchantListTotal: 0,
      product: [],
    }
  },
  mounted() {
    this.getMerchantList()
    this.merchantDetailsFn()
  },
  methods: {
    async handleSearch() {
      this.getMerchantList(1)
    },
    async getMerchantList(type = 0) {
      const {
        data: { code, data },
      } = await post_merchant_list({ limit: 2, page: 1, is_pavilion: 1, type })
      if (code == 1) {
        this.merchantListTotal = data.total
        this.merchantListData = data.data.map((item) => ({
          ...item,
          image: IMAGE_URL + item.image,
          images: IMAGE_URL + item.images,
          logo: IMAGE_URL + item.logo,
          yyzz_images: IMAGE_URL + item.yyzz_images,
        }))
      } else {
        message.error('数据获取失败, 请重试')
      }
    },
    async merchantDetailsFn(id) {
      const {
        data: {
          data: { product, shop },
        },
      } = await get_merchant_details({ id, page: 1, limit: 8 })
      this.product = product.data.map((item) => ({
        ...item,
        image: IMAGE_URL + item.image,
        images: IMAGE_URL + item.images,
        shop_id: id,
      }))
    },
  },
})
</script>

<style lang="less" scoped>
.wrapper {
  // min-width: 1600px;
  background: #f1f6f4;
  .unit {
    margin: 80px auto;
    width: 66%;
    .content {
      margin-top: 50px;
      .item {
        margin-top: 20px;
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        // justify-content: space-around;
        span {
          margin-right: 20px;
          font-size: 16px;
          line-height: 30px;
          color: #333;
          &.title {
            padding-right: 10px;
            flex: 0 0 90px;
            display: block;
            height: 35px;
            width: 90px;
            color: #fff;
            text-align: center;
            line-height: 35px;
            background: url(../../assets/image/tab.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .present {
    max-width: 1240px;
    min-width: 1240px;
    padding-top: 150px;
    margin: 30px auto;
    // width: 66%;
    .content {
      margin-top: 50px;
      display: flex;
      height: 365px;
      -moz-box-shadow: 0px 1px 5px #ddd;
      -webkit-box-shadow: 0px 1px 5px #ddd;
      box-shadow: 0px 1px 5px #ddd;
      .description {
        flex: 1;
        background: #fff;
        padding: 40px;
        color: #4e4e4e;
        font-size: 16px;
        line-height: 30px;
      }
      .content-right {
        flex: 0 0 320px;
        background: url(../../assets/image/rightbg.jpg) no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          padding: 50px 40px 0 40px;
          span {
            font-size: 18px;
            display: block;
            line-height: 35px;
            &:nth-child(2) {
              margin-top: 10px;
              font-size: 28px;
              &::after {
                content: '';
                display: block;
                width: 30px;
                border: 2px solid #fff;
                margin-top: 15px;
              }
            }
          }
        }
        .bot {
          width: 100%;
          height: 72px;
          border-top: 1px solid #33c887;
          text-align: center;
          line-height: 72px;
          font-size: 18px;
        }
      }
    }

    .title {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
