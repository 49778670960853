<template>
  <div class="header">
    <div class="operate">
      <img
        style="width: 125px; margin-right: 80px"
        src="../../assets/image/headerlogo.png"
        alt=""
      />
      <div
        class="span"
        :class="{ active: current == index }"
        v-for="(item, index) of operateData"
        :key="item"
        @click="handleJump(index)"
      >
        {{ item }}
      </div>
        <a-input-search
        style="margin-left: 40px; height: 40px; flex: 0 0 300px; display: flex;"
          v-model="searchValue"
          placeholder="输入要搜索的内容"
          @search="onSearch"
        />
    </div>
    <img v-if="!exhibitorPage" src="../../assets/image/banner.jpg" alt="" />
    <img v-if="indexPage" class="press-img" src="../../assets/image/12.jpg" alt="" />
    <iframe v-if="exhibitorPage" style="height: 550px" src="http://vrl.huizhanonline.com/showhall/index.html?vid=2575" frameborder="0"></iframe>
    <!-- <div v-if="exhibitorPage" class="allscreen">全屏</div> -->
    <!-- <div class="breadcrumb" v-if="!indexPage">
      <img src="../../assets/image/m-1.png" alt="" />
      <img class="right-arrow" src="../../assets/image/rightjiantou.png" alt="" />
      <span @click="handleJump('/')">首页</span>
      <img class="right-arrow" src="../../assets/image/rightjiantou.png" alt="" />
      <span>{{ titleName }}</span>
      <img v-if="isDetails || isGoodDetails" class="right-arrow" src="../../assets/image/rightjiantou.png" alt="" />
      <span v-if="isDetails">企业简介</span>
      <span v-if="isGoodDetails">展品详情</span>
    </div> -->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  props: {
    indexPage: {
      type: Boolean,
      default: false,
    },
    exhibitorPage: {
      type: Boolean,
      default: false,
    },

    // headerData: Object,
    // goodsName: String,
  },
  setup(props, ctx) {
    const router = useRouter()
    const route = useRoute()
    const current = ref(0)
    const titleName = ref('')
    const isDetails = ref(false)
    const isGoodDetails = ref(false)
    const searchValue = ref('')
    const listItem = ref('展团')
    const listData = reactive(['展团', '展品', '展馆', '采访'])
    onMounted(() => {
      if ('details' == route.name) isDetails.value = true
      if ('goods_details' == route.name) isGoodDetails.value = true
      current.value = route.query.path || 0
      switch (+route.query.path) {
        case 1:
          return (titleName.value = '展团展区')
        case 2:
          return (titleName.value = '展品列表')
        case 3:
          return (titleName.value = '云上展馆')
        case 4:
          return (titleName.value = 'VR采访')
        default:
          break
      }
      // switch (+route.query.path) {
      //   case 1:
      //     return (titleName.value = "展团列表");
      //   case 2:
      //     return (titleName.value = "参会展商");
      //   case 3:
      //     return (titleName.value = "展品列表");
      //   case 4:
      //     return (titleName.value = "云上展馆");
      //   case 5:
      //     return (titleName.value = "VR采访");
      //   default:
      //     break;
      // }
    })
    const onSearch = () => {
      ctx.emit('onSearch', searchValue.value)
    }
    const handleJump = (path, id = '') => {
      if (typeof path == 'number') {
        switch (path) {
          case 0:
            return router.push({ path: '/' })
          case 1:
            return router.push({ path: '/pavilion', query: { path } })
          case 2:
            return router.push({ path: '/goodslist', query: { path } })
          case 3:
            return router.push({ path: '/cloud', query: { path } })
          case 4:
            return router.push({ path: '/access', query: { path } })
          // case 0:
          //   return router.push({ path: "/" });
          // case 1:
          //   return router.push({ path: "/pavilion", query: { path } });
          // case 2:
          //   return router.push({ path: "/details", query: { path } });
          // case 3:
          //   return router.push({ path: "/goodslist", query: { path } });
          // case 4:
          //   return router.push({ path: "/cloud", query: { path } });
          // case 5:
          //   return router.push({ path: "/access", query: { path } });
          default:
            break
        }
      }
      router.push({
        path,
        query: { id },
      })
    }
    return {
      handleJump,
      onSearch,
      current,
      titleName,
      isDetails,
      isGoodDetails,
      listItem,
      listData,
      searchValue,
    }
  },
  data() {
    return {
      info: {},
      operateData: ['网站首页', '展团展区', '展品列表', '云上展馆', 'VR采访'],
    }
  },
  mounted() {
    if (localStorage.getItem('HEADER_INFO'))
      this.info = JSON.parse(localStorage.getItem('HEADER_INFO'))
  },
  watch: {
    // headerData(value) {
    //   if (localStorage.getItem("HEADER_INFO"))
    //     this.info = JSON.parse(localStorage.getItem("HEADER_INFO"));
    //   if (value.name == undefined) {
    //     this.info = JSON.parse(localStorage.getItem("HEADER_INFO"));
    //   } else {
    //     this.info = value;
    //   }
    // },
  },
})
</script>
<style lang="less" scoped>
// .allscreen {
//   position: absolute;
//   top: 0;
// }
.header {
  width: 100%;
  min-width: 1360px;
  // min-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
  .press-img {
    position: absolute;
    bottom: -110px;
    left: 50%;
    margin-left: -620px;
    max-width: 1240px;
    min-width: 1240px;
    -moz-box-shadow: 0px 2px 10px #ddd;
    -webkit-box-shadow: 0px 2px 10px #ddd;
    box-shadow: 0px 2px 10px #ddd;
  }
  .operate {
    height: 70px;
    background: #fff;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    .span {
      cursor: pointer;
      flex: 0 0 130px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &.active {
        height: 100%;
        background: #00b868;
        color: #fff;
      }
    }
  }
  .breadcrumb {
    padding: 20px 15%;
    color: #999;
    .right-arrow {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      cursor: auto;
    }
    img,
    span {
      cursor: pointer;
    }
  }
  .back-home {
    cursor: pointer;
    position: absolute;
    top: 3%;
    right: 15%;
  }
  .header-content {
    position: absolute;
    width: 100%;
    height: 62%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .name {
      display: block;
      width: 100%;
      text-align: center;
      min-height: 40px;
      color: #3c8743;
      font-size: 60px;
      font-weight: bold;
      margin: 40px 0;
    }
    .number {
      // margin-top: 30px;
      width: 320px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f0f0f0;
      font-size: 26px;
      background: url(../../assets/image/zhanweiBG.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .btn-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    img {
      cursor: pointer;
      width: 260px;
      height: 70px;
      margin-left: 50px;
      &::nth-child(1) {
        margin: 0;
      }
    }
    .btn-item {
      align-items: center;
      width: 180px;
      height: 50px;
      background: #7eab54;
      margin-left: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 4px 4px 0px rgba(135, 196, 95, 0.2);
      &::nth-child(1) {
        margin: 0;
      }
      img {
        flex: 0 0 20%;
        width: 30px;
        height: 30px;
      }
      span {
        margin-left: 20px;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
  }
  .header-image {
    width: 100%;
    flex: 0 0 80%;
  }
}
</style>
