<template>
  <div class="goods-list-wrap">
    <div class="title">
      <img src="../../assets/image/chanpin.png" alt="" />
    </div>
    <div class="goods-list-box">
      <div class="nodata" v-if="product.length == 0">
        <a-empty :image="simpleImage">
          <template #description>暂无数据</template>
        </a-empty>
      </div>
      <div class="goods-list-item" v-for="item of product" :key="item.id">
        <div class="img-box" @click="handleJump(item.id, item.shop_id)">
          <div style="overflow: hidden; width: 100%; height: 100%;">
            <img :src="item.image" alt="" />
          </div>
        </div>
        <div class="goods-desc">
          <span style="font-size: 14px;">{{ item.name }}</span>
          <img @click="handleJump(item.id, item.shop_id)" src="../../assets/image/youjiantou.png" alt="" class="icon" />
        </div>
      </div>
    </div>
    <div class="more-exhibitor" v-if="indexPage" @click="handleJump(-1)">
      更多展品
    </div>
    <a-pagination v-else @change="index => this.$emit('emitChange', index)" class="pagination" v-model="currentPage" :hideOnSinglePage="true"
      :defaultPageSize="12" :total="total" />
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Empty } from 'ant-design-vue'
export default defineComponent({
  props: {
    product: Array,
    indexPage: {
      type: Boolean,
      defaule: false,
    },
    total: Number,
    currentPage: Number,
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    onMounted(() => {
    })
    const handleJump = (id, shopid) => {
      if (id == -1) router.push({ path: '/goodslist', query: { path: 2 } })
      else
        router.push({
          path: '/goods_details',
          query: { id, shopid, path: 2 },
        })
    }
    return {
      handleJump,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
})
</script>
<style lang="less" scoped>
.more-exhibitor {
  margin: 50px auto;
  width: 160px;
  height: 50px;
  // padding: 10px 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
  color: #bbecd7;
  cursor: pointer;
  background: #00b868;
}
.nodata {
  width: 100%;
  margin: 50px auto;
}
.pagination {
  margin-top: 60px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.goods-list-wrap {
  margin-top: 50px;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  .goods-list-box {
    max-width: 1240px;
    min-width: 1240px;
    //   height: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    .goods-list-item {
      margin-top: 50px;
      // flex: 0 0 23%;
      width: 23.6%;
      // height: 300px;
      &:last-child:nth-child(4n - 1) {
        margin-right: calc(24% + 4% / 3);
      }
      &:last-child:nth-child(4n - 2) {
        margin-right: calc(48% + 8% / 3);
      }
      -moz-box-shadow: 0px 2px 5px #ddd;
      -webkit-box-shadow: 0px 2px 5px #ddd;
      box-shadow: 0px 2px 5px #ddd;
      transition: all 0.5s;
      &:hover {
        -moz-box-shadow: 0px 0px 15px #bbb;
        -webkit-box-shadow: 0px 0px 15px #bbb;
        box-shadow: 0px 0px 15px #bbb;
      }
      .goods-desc {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      .img-box {
        //   max-width: 290px;
        //   max-height: 290px;
        overflow: hidden;
        cursor: pointer;
        padding: 10px 10px 0 10px;
        background: #fff;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
