import axios from 'axios'
axios.defaults.baseURL = 'https://nonggaohui.huizhanonline.com';
const  askConfig = (url, data, method = 'POST') => {
    return axios({ method, url, data })
}

export const IMAGE_URL = 'https://nonggaohui-2021.oss-cn-beijing.aliyuncs.com'
// 商家列表/展团
export const post_merchant_list = data => askConfig('/api/shop/index', data)
// 商家详情
export const get_merchant_details = data => askConfig('/api/shop/show', data)
// 商品详情
export const get_goods_details = data => askConfig('/api/Product/show', data)
// 商品列表
export const post_product_list = data => askConfig('/api/product/index', data)
// vr采访列表
export const get_vr_access = data => askConfig('/api/panorama_video/index', data)
