<template>
  <div class="ca-wrap">
    <div class="title">
      <img v-if="!isVR && !indexPage" src="../../assets/image/yunshang.png" alt="" />
      <img v-if="isVR" src="../../assets/image/vr-title.png" alt="" />
      <img v-if="indexPage" src="../../assets/image/zhantuanliebiao.png" alt="" />
    </div>
    <div class="ca-content">
      <div class="ca-item" v-for="item of merchantListData" :key="item.id">
        <!-- <iframe style="width: 100%; height: 100%; position: absolute;"
          src="//player.bilibili.com/player.html?aid=721373816&bvid=BV1CQ4y1q7w6&cid=430544018&page=1" scrolling="no" border="0" frameborder="no"
          framespacing="0" allowfullscreen="true"> </iframe> -->
        <div class="img">
          <img @click="handleJump(indexPage, item.id)" :src="item.image" />
        </div>
        <div class="item-contnt">
          <div class="cl">
            <span>{{ item.name }}</span>
            <span>云上展厅</span>
          </div>
          <div class="cr">
            <img v-if="!isVR && !indexPage" src="../../assets/image/vr.png" alt="" />
            <img v-if="isVR" @click="playVideo(item.video_file)" src="../../assets/image/video.png" alt="" />
            <img v-if="indexPage" style="width: 50px; height: 50px; border-radius: 50%" :src="item.logo" alt="" />
          </div>
        </div>
      </div>
    </div>

    <a-pagination v-if="!indexPage" class="pagination" :hideOnSinglePage="true" @change="index => this.$emit('emitChange', index)" :total="total" />
    <div v-else @click="handleJump(indexPage, -1)" class="more-exhibitor">更多展团</div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    indexPage: {
      type: Boolean,
      default: false,
    },
    isVR: {
      type: Boolean,
      default: false,
    },
    merchantListData: Array,
    total: Number,
  },
  setup() {
    const router = useRouter()
    const playVideo = (path) => {
      params.scenesArr[0].sceneFilePath = path
      const videoWrapper = document.getElementById('video-vr')
      const videoItem = document.getElementById('video-item')
      videoWrapper.style.display = 'block'
      videoItem.style.display = 'block'
      window.scrollTo(0, 0)
      initLoad(params)
    }
    const handleJump = (isIndex, id) => {
      if (id == -1)
        router.push({
          path: '/pavilion',
          query: { path: 1 },
        })
      else
        router.push({
          path: '/details',
          query: { path: 1, id },
        })
    }
    return {
      handleJump,
      playVideo,
    }
  },
})
</script>
<style lang="less" scoped>
.more-exhibitor {
  margin: 50px auto 0 auto;
  width: 160px;
  height: 50px;
  // padding: 10px 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
  color: #bbecd7;
  cursor: pointer;
  background: #00b868;
}
.ca-wrap {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  min-width: 1240px;
  margin: 0 auto;
  .ca-content {
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .ca-item {
      margin-top: 20px;
      background: #fff;
      flex: 0 0 49%;
      padding: 10px;
      -moz-box-shadow: 0px 2px 5px #ddd;
      -webkit-box-shadow: 0px 2px 5px #ddd;
      box-shadow: 0px 2px 5px #ddd;
      transition: all 0.5s;
      &:hover {
        -moz-box-shadow: 0px 0px 15px #bbb;
        -webkit-box-shadow: 0px 0px 15px #bbb;
        box-shadow: 0px 0px 15px #bbb;
      }
      .item-contnt {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        .cl {
          flex: 0 0 80%;
          padding-left: 10px;
          span {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:nth-child(1) {
              color: #333;
              font-size: 18px;
            }
            &:nth-child(2) {
              margin-top: 8px;
              color: #999;
            }
          }
        }
        .cr {
          padding-right: 10px;
        }
      }
      .img {
        width: 100%;
        height: 330px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .title {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pagination {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
}
</style>
